import React, { Component } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from '../../layout/layout'
import Recent from '../../components/recent/index.js'
import './case-study.scss'
import '../content-classes/wp-classes.scss'
import Seo from '../../components/seo'
class Post extends Component {
  
  render() {
    const post = this.props.data.wordpressCaseStudies ;
    // console.log("this is case study")
    // console.log(this.props.data)
    return (
      <>
        <Layout>
        <Seo postTitle={post.title} />
        <div className="post_content">
        <h1>{post.title}</h1>
        <div className="wordpress_content" dangerouslySetInnerHTML={{ __html: post.content }} />
        </div>
        <Recent/>
      </Layout>
      </>
    )
  }
}

Post.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default Post

export const postQuery = graphql`
query ($id: String!) {
  wordpressCaseStudies(id: {eq: $id}) {
    title
    content
    modified
  }
  site {
    siteMetadata {
      title
      description
    }
  }
}
`
